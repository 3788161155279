<template>
    <div class="dating-feedback">
        <StackRouterHeaderBar />
        <main class="main">
            <FeedbackTop
                class="m-b-40"
                :title="'계속 연락/만남을 가질<br />의사가 있으신가요?'"
                :description="'상대방과 주선자에게 공개되지 않아요'"
            />
            <FeedbackOptionItems v-model="items" @select="onSelectItems" />
        </main>

        <footer class="footer">
            <FeedbackDescription />
        </footer>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import FeedbackOptionItems from './components/FeedbackOptionItems'
import FeedbackDescription from './components/FeedbackDescription'

export default {
    name: 'DatingFeedbackPage',
    components: { FeedbackTop, FeedbackOptionItems, FeedbackDescription },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        items: [],
    }),
    methods: {
        async init() {
            const options = await this.$store.dispatch('filterFeedbackOptionByCategory', 'continue_meeting')
            this.items = options.map(option => {
                this.$set(option, 'selected', false)
                return option
            })
        },
        onSelectItems(idx) {
            this.$store.commit('setSelectedFeedbacks', { category: 'contact', feedbakcs: [{ id: this.items[idx].id }] })
            if (idx === 0) {
                this.$stackRouter.push({
                    name: 'RecommendableReasonPage',
                    props: {
                        type: 'meet',
                        user: this.user,
                    },
                })
            } else {
                this.$stackRouter.push({
                    name: 'RecommendablePersonPage',
                    props: {
                        type: 'meet',
                        user: this.user,
                    },
                })
            }

            // this.$stackRouter.push({
            //     name: 'DatingSincerityPage',
            //     props: { user: this.user },
            // })
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-feedback {
    .main {
        padding: 0 16px;
    }

    .footer {
        position: absolute;
        bottom: 0;
        padding: 0 16px 40px;
    }
}
</style>
